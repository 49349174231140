.headerMenu {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 102;
  display: none;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
  overflow: auto;
  padding-bottom: 100px;
  background: white;

  &--visible {
    display: flex;
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: $header-height;
    width: 100%;
    border-bottom: 1px solid $border-color;
    margin-bottom: 30px;
    padding: 0 20px;

    @media screen and (min-width: $tablet-breakpoint) {
      height: $header-height-desktop;
    }

    &Logo {
      height: 32px;
    }
  }

  &-close {
    cursor: pointer;

    & > .icon,
    & > .icon svg {
      height: 32px;
      width: 32px;
    }
  }

  &-contacts {
    display: flex;
    align-items: center;
    margin-left: 40px;
    margin-top: 10px;

    & > .icon {
      height: 24px;
      width: 24px;
      margin-right: 10px;

      & svg {
        height: 24px;
        width: 24px;
      }
    }
  }

  &-link {
    margin-left: 40px;
    margin-top: 10px;
    margin-right: 40px;
    border-bottom: 1px solid $border-color;

    &:first-child {
      margin-top: 50px;
    }

    &:last-child {
      border-bottom: none;
    }

    & a,
    & span {
      display: block;
      padding: 5px;
    }
  }
}
