.homeSection {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: calc(100vh - $header-height);
  width: 100%;
  margin-bottom: 100px;
  background-size: cover;
  background-repeat: no-repeat;

  @media screen and (min-width: $tablet-breakpoint) {
    min-height: calc(100vh - $header-height-desktop);
  }

  &-header {
    position: relative;
    padding: 20px;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 50%;
      margin-top: 100px;
    }

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      display: block;
      height: 100%;
      width: 100%;
      background-color: rgba(238, 238, 238, 0.7);
      filter: blur(4px);
    }

    & > h1 {
      position: relative;
      z-index: 2;
      font-size: 1.75rem;
      font-family: "Montserrat-800", sans-serif;
      text-transform: uppercase;
      text-align: center;

      @media screen and (min-width: $tablet-breakpoint) {
        font-size: 3rem;
      }
    }
  }

  &-description {
    position: relative;
    z-index: 2;
  }

  &-beforeChildren {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    & span {
      margin-bottom: 10px;
      font-size: 1.10rem;

      &:last-child {
        margin: 20px auto 10px;
        text-align: center;
      }
    }
  }

  &-afterChildren {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & span:first-child {
      font-size: 1.10rem;
      margin-bottom: 10px;
    }

    & a {
      padding: 15px 10px;
      font-family: "Montserrat-800", sans-serif;
      color: #fff;
      background-color: $button-color;
    }
  }

  & button {
    position: relative;
    z-index: 2;
    display: block;
    margin: 0 auto;
  }
}
