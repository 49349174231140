.contactsSection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 10px;
  column-gap: 10px;
  margin-bottom: 20px;

  &-item {
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;

    & .icon {
      margin-right: 10px;
    }

    & .icon,
    & .icon svg {
      height: 24px;
      width: 24px;
    }
  }

  &-text {
    margin-left: 34px;
    font-size: 0.9rem;
  }
}
