.spoiler {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px dashed $border-color;
    padding-bottom: 10px;
    padding-right: 5px;
    color: $link-color;
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      border-bottom-style: solid;
    }

    &:hover {
      color: $link-color-hover;
    }

    &--visible {

      & > .icon {
        transform: rotate(180deg);
      }
    }

    & > h3 {
      font-family: "Montserrat-500", sans-serif;
      font-weight: 500;
      font-size: 1rem;
      line-height: 1.5;
    }
  }

  &-description {
    display: none;
    padding: 10px 5px;
    font-size: 0.9rem;
    line-height: 2;

    &--visible {
      display: block;
    }
  }
}
