.callModal {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  padding: 50px 20px;
  background-color: #ffffff;

  &-header {
    margin-bottom: 30px;

    &Title {
      margin-bottom: 20px;
      font-size: 1rem;
      font-family: "Montserrat-600", sans-serif;
      text-align: center;
    }

    &Description {
      font-size: 0.9rem;
      text-align: center;
    }
  }

  & .textField {
    margin-bottom: 20px;
  }

  &-error {
    margin-bottom: 20px;
    padding: 10px;
    font-size: 0.85rem;
    line-height: 1.5;
    background-color: #cc4400;
    color: #ffffff;
  }

  &-close {
    position: absolute;
    top: 9px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 48px;
      height: 48px;
    }

    & .icon,
    & svg {
      width: 32px;
      height: 32px;

      @media screen and (min-width: $tablet-breakpoint) {
        width: 48px;
        height: 48px;
      }
    }
  }

  &-submitted {
    margin-bottom: 30px;
    font-size: 0.9rem;
    line-height: 1.5;
    text-align: center;
  }

  & .button {
    margin-bottom: 20px;
  }
}
