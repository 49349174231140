.button {
  border-radius: 0;
  border: none;
  padding: 15px 10px;
  font-family: "Montserrat-800", sans-serif;
  cursor: pointer;

  &-fill {
    color: #fff;
    background-color: $button-color;
  }

  &--disabled {
    color: rgba(16, 16, 16, 0.3);
    background-color: rgba(239, 239, 239, 0.3);
    cursor: not-allowed;
  }
}
