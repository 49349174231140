*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  height: 100%;
  width: 100%;
  border: none;
  font-size: 100%;
  font-weight: 400;
  font-family: "Montserrat-500", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.2;
  color: $main-text-color;
}

ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 600;
  word-break: break-word;
}

a {
  color: $link-color;
  text-decoration: none;
  word-break: break-word;

  &:hover {
    color: $link-color-hover;
  }
}

#root {
  height: 100%;
  width: 100%;
}
