.faqSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 992px;
  margin: 0 auto;

  &-answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin: 0 auto;

    @media screen and (min-width: $tablet-breakpoint) {
      flex-direction: row;
      align-items: flex-start;
      column-gap: 50px;
    }
  }

  &-image {
    width: 70%;
    margin-bottom: 20px;

    @media screen and (min-width: $tablet-breakpoint) {
      flex: 1;
      margin-bottom: 0;
    }
  }

  &-items {

    @media screen and (min-width: $tablet-breakpoint) {
      flex: 2;
    }
  }

  &-call {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 30px;

    &Title {
      padding-bottom: 10px;
      font-size: 1.5rem;
      text-align: center;
    }

    &Description {
      padding-bottom: 30px;
      text-align: center;
      color: #969da3;
    }

    &Form {

      &--submitted {
        line-height: 1.5;
        text-align: center;
      }

      & .textField,
      & button {
        min-width: 300px;

        @media screen and (min-width: $tablet-breakpoint) {
          display: inline-block;
        }
      }

      & .textField {
        margin-bottom: 10px;

        @media screen and (min-width: $tablet-breakpoint) {
          margin-bottom: 0;
          margin-right: 10px;
        }
      }
    }
  }
}
