// Colors
$main-text-color: #374048;
$link-color: #306eea;
$link-color-hover: #cc4400;
$border-color: #eeeeee;
$button-color: #306eea;

// Sizes
$header-height: 50px;
$header-height-desktop: 100px;
$footer-height: 100px;

// Breakpoints
$tablet-breakpoint: 720px;
