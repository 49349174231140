.imageModal {

  &-image {
    display: none;
    width: 100%;

    @media screen and (min-width: $tablet-breakpoint) {
      padding: 10%;
    }

    &--visible {
      display: block;
    }
  }

  &-close {
    position: absolute;
    top: 9px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.5);
    color: #ffffff;
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 48px;
      height: 48px;
    }

    & .icon,
    & svg {
      width: 32px;
      height: 32px;

      @media screen and (min-width: $tablet-breakpoint) {
        width: 48px;
        height: 48px;
      }
    }
  }
}
