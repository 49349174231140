.textField {
  position: relative;

  &-label {
    position: absolute;
    left: 15px;
    top: -8px;
    padding: 0 5px;
    font-size: 0.8rem;
    background-color: #ffffff;
  }

  & input {
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 0;
    padding: 15px 10px;
    font-size: 0.9rem;
    outline: none;

    &:focus {
      border-radius: 0;
      border-color: $link-color;
    }
  }
}
