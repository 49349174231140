.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 16px;
  width: 16px;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;

  & svg {
    height: 16px;
    width: 16px;
  }
}
