.calculationSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 992px;
  margin: 0 auto;

  &-block {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &--visible {
      display: flex;
    }

    h3 {
      margin-bottom: 20px;
      text-align: center;
    }

    &Items {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;

      & .textField,
      & .checkButton {
        margin-right: 10px;
        margin-bottom: 10px;
      }

      & .textField {
        display: inline-block;
      }
    }
  }

  &-nav {
    & .button {
      display: inline-block;
      margin-right: 10px;
    }
  }
}
