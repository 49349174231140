.case {
  width: 100%;
  margin-bottom: 30px;

  @media screen and (min-width: $tablet-breakpoint) {
    width: 300px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &-image {
    height: 300px;
    width: 100%;
    cursor: pointer;
  }

  &-title {
    font-size: 1.17rem;
    line-height: 1.5;
    font-family: "Montserrat-800", sans-serif;
  }

  &-description {
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 2;
    font-size: 0.9rem;
  }

  &-price {
    font-family: "Montserrat-600", sans-serif;
  }
}
