.kitchens {

  & .homeSection {
    background-image: url("../../assets/images/homeSection/kitchens_m.png");

    @media screen and (min-width: $tablet-breakpoint) {
      background-image: url("../../assets/images/homeSection/kitchens.png");
    }
  }
}
