.modalBackdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 102;
  height: 100%;
  width: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;

  &--visible {
    display: flex;
  }
}
