.closets {

  & .homeSection {
    background-image: url("../../assets/images/homeSection/closets_m.png");

    @media screen and (min-width: $tablet-breakpoint) {
      background-image: url("../../assets/images/homeSection/closets.png");
    }
  }
}
