.howWorkSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 30px;
  column-gap: 10px;
  max-width: 992px;
  height: auto;
  padding-top: 30px;

  @media screen and (min-width: $tablet-breakpoint) {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;
    margin: 0 auto;
  }

  &-item {
    position: relative;
    min-width: 300px;
    width: 300px;
    max-width: 300px;
    border: 1px solid $border-color;
    padding: 30px 10px 10px 10px;

    &Count {
      position: absolute;
      left: calc(50% - 20px);
      top: -20px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      width: 40px;
      border: 1px solid $link-color;
      border-radius: 50%;
      font-size: 1.5rem;
      font-family: "Montserrat-800", sans-serif;
      color: $link-color;
      background-color: #ffffff;
    }

    &Title {
      margin-bottom: 20px;
      text-align: center;
      font-family: "Montserrat-600", sans-serif;
    }

    &Description,
    &Sample {
      font-size: 0.9rem;
      line-height: 2;
    }

    &Sample {
      color: $link-color;
      cursor: pointer;

      &:hover {
        color: $link-color-hover;
      }
    }
  }
}
