.slider {
  position: relative;

  &-button {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 32px;
    width: 32px;
    border: none;
    border-radius: 50%;
    background-color: $border-color;
    cursor: pointer;

    @media screen and (min-width: $tablet-breakpoint) {
      width: 48px;
      height: 48px;
    }

    &--disabled {

      & > .icon {
        opacity: 0.3;
      }
    }

    & .icon {
      color: $main-text-color;
    }

    & .icon,
    & svg {

      @media screen and (min-width: $tablet-breakpoint) {
        width: 24px;
        height: 24px;
      }
    }

    &Prev,
    &Next {
      top: 0;
      bottom: 0;
      margin: auto 0;
    }

    &Prev {
      left: 0;

      & .icon {
        transform: rotate(90deg);
      }
    }

    &Next {
      right: 0;

      & .icon {
        transform: rotate(270deg);
      }
    }
  }

  &-container {
    padding: 0 20px;
  }
}
