.content {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ($header-height + $footer-height));

  @media screen and (min-width: $tablet-breakpoint) {
    min-height: calc(100vh - ($header-height-desktop + $footer-height));
  }

  & > div {
    width: 100%;
  }
}
