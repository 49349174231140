.clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 30px;
  column-gap: 30px;
  max-width: 1280px;
  margin: 0 auto;

  &-item {

    & img {
      height: 40px;
    }
  }
}
