.checkButton {
  display: inline-block;
  padding: 15px 10px;
  border: 1px solid $link-color;
  font-size: 1rem;
  cursor: pointer;

  &--checked {
    background-color: $link-color;
    color: #ffffff;
  }
}
