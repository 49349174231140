.pageSection {
  width: 100%;
  margin-bottom: 100px;
  padding: 0 10px;

  &-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &:after {
      display: block;
      height: 6px;
      width: 82px;
      margin: 15px auto;
      content: '';
      background: $border-color;
    }
  }

  &-title {
    text-align: center;
    text-transform: uppercase;
    font-family: "Montserrat-800", sans-serif;
  }

  &-description {
    text-align: center;
    margin-top: 20px;
    color: #969da3;
  }
}
