.privacy {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 992px;
  margin: 0 auto;
  padding: 50px 20px;

  & h1 {
    margin-bottom: 30px;
    font-size: 1.5rem;
  }

  & h2 {
    margin: 20px 0;
    font-size: 1.25rem;
    line-height: 1.5;
  }

  & p,
  & ul,
  & li {
    margin-bottom: 10px;
    line-height: 2;
  }

  & p {
    margin-left: 15px;
  }

  & li {
    margin-left: 40px;
    list-style: disc;
  }
}
