.promo {
  width: 100%;
  margin-bottom: 100px;
  padding: 30px;
  text-align: center;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: bolder;
  color: #eeeeee;
  background-color: #cc4400;

  & span {
    font-size: 2rem;
    vertical-align: middle;
  }
}
