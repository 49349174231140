@-webkit-keyframes move {
  0% {
    transform: translateY(0px)
  }

  50% {
    transform: translateY(25px)
  }

  100% {
    transform: translateY(0px)
  }
}

@-moz-keyframes move {
  0% {
    transform: translateY(0px)
  }

  50% {
    transform: translateY(25px)
  }

  100% {
    transform: translateY(0px)
  }
}

@-o-keyframes move {
  0% {
    transform: translateY(0px)
  }

  50% {
    transform: translateY(25px)
  }

  100% {
    transform: translateY(0px)
  }
}

@keyframes move {
  0% {
    transform: translateY(0px)
  }

  50% {
    transform: translateY(25px)
  }

  100% {
    transform: translateY(0px)
  }
}

.bubbles {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;

  & div {
    position: absolute;
    z-index: 1;
    border-radius: 50%;
  }

  & div:nth-child(1) {
    left: 20px;
    top: 20px;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.2);
    animation: move 3.5s linear infinite;

    @media screen and (min-width: $tablet-breakpoint) {
      left: 40px;
      top: 40px;
    }
  }

  & div:nth-child(2) {
    right: 20px;
    top: 50px;
    width: 80px;
    height: 80px;
    background-color: rgba(255, 255, 255, 0.2);
    animation: move 4s linear infinite;

    @media screen and (min-width: $tablet-breakpoint) {
      left: 45%;
      top: 50px;
    }
  }

  & div:nth-child(3) {
    right: 45%;
    top: 20%;
    width: 60px;
    height: 60px;
    background-color: rgba(255, 255, 255, 0.5);
    animation: move 5.5s linear infinite;

    @media screen and (min-width: $tablet-breakpoint) {
      right: 40px;
      top: 140px;
    }
  }

  & div:nth-child(4) {
    left: 50%;
    top: 35%;
    width: 170px;
    height: 170px;
    background-color: rgba(255, 255, 255, 0.3);
    animation: move 9.5s linear infinite;

    @media screen and (min-width: $tablet-breakpoint) {
      left: 30%;
      top: 60%;
    }
  }
}
