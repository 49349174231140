.notFound {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - ($header-height + $footer-height));
  padding: 50px 20px;

  @media screen and (min-width: $tablet-breakpoint) {
    height: calc(100vh - ($header-height-desktop + $footer-height));
  }

  &-title {
    font-size: 5rem;
    font-family: "Montserrat-800", sans-serif;
  }

  &-description {
    margin: 20px 0;
    text-align: center;
    font-size: 0.9rem;
  }
}
