// 300
@font-face {
  font-family: "Montserrat-300";
  src: url("../../assets/fonts/Montserrat-Light.ttf");
};

// 500
@font-face {
  font-family: "Montserrat-500";
  src: url("../../assets/fonts/Montserrat-Medium.ttf");
};

// 600
@font-face {
  font-family: "Montserrat-600";
  src: url("../../assets/fonts/Montserrat-SemiBold.ttf");
};

// 800
@font-face {
  font-family: "Montserrat-800";
  src: url("../../assets/fonts/Montserrat-ExtraBold.ttf");
};
