.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: $footer-height;

  & a,
  &-year,
  &-legal,
  &-public {
    font-size: 0.9rem;
  }

  & a {
    margin-bottom: 30px;
    text-align: center;
  }

  &-year,
  &-legal {
    margin-bottom: 10px;
  }

  &-year {
    font-family: "Montserrat-600", sans-serif;
  }

  &-public {
    padding-bottom: 50px;
    font-family: "Montserrat-300", sans-serif;
  }
}
